import React, { useState } from 'react';
import { withBrowserImage } from 'components/browser-image';

const BrowserImage = withBrowserImage();
/**
 * 图片预览组件，需要子节点点击事件冒泡打开。
 */
const PreviewImage = ({ children }) => {
    const [showPreviewImg, setShowPreviewImg] = useState(false);
    const [previewImgSrc, setPreviewImgSrc] = useState('');

    // 子节点被点击时代理点击事件， 如果是图片被点击，打开图片放大组件
    const handleClick = (e) => {
        if (
            !e.target
            || !e.target.src
            || e.target.nodeName !== 'IMG'
        ) {
            return;
        }

        setShowPreviewImg(true);
        setPreviewImgSrc(e.target.src);
    };

    // 关闭图片预览
    const onBrowserImageClose = () => {
        setShowPreviewImg(false);
        setPreviewImgSrc('');
    };
    return (
        <div
            className="preview-image"
            onClick={handleClick}
            role="none"
        >
            {showPreviewImg && (
                <BrowserImage
                    showBrowser
                    src={previewImgSrc}
                    onClose={onBrowserImageClose}
                />
            )}
            {children}
        </div>
    );
};

export default PreviewImage;